const countries = {
  SG: {
    name: 'Singapore',
    id: 153,
    dialCode: '+65',
    wisenetCode: '5205',
    rapidId: 'SG',
    paypalAllowed: false,
    countryCode: 'SG',
    flag: '🇸🇬'
  },
  PH: {
    name: 'Philippines',
    id: 139,
    dialCode: '+63',
    wisenetCode: '5204',
    rapidId: 'PH',
    paypalAllowed: false,
    countryCode: 'PH',
    flag: '🇵🇭'
  },
  IN: {
    name: 'India',
    id: 79,
    dialCode: '+91',
    wisenetCode: '7103',
    rapidId: 'IN',
    paypalAllowed: false,
    countryCode: 'IN',
    flag: '🇮🇳'
  },
  MY: {
    name: 'Malaysia',
    id: 125,
    dialCode: '+60',
    wisenetCode: '5203',
    rapidId: 'MY',
    paypalAllowed: false,
    countryCode: 'MY',
    flag: '🇲🇾'
  },
  ID: {
    name: 'Indonesia',
    id: 78,
    dialCode: '+62',
    wisenetCode: '5202',
    rapidId: 'ID',
    // rapidId: 'NIL',
    paypalAllowed: false,
    countryCode: 'ID',
    flag: '🇮🇩'
  }
};

export const countryList = [
  {
    name: 'Australia',
    id: 9,
    dialCode: '+61',
    wisenetCode: '1101',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'AU',
    flag: '🇦🇺'
  },
  { ...countries.IN },
  { ...countries.ID },
  { ...countries.MY },
  { ...countries.PH },
  { ...countries.SG },
  {
    name: 'United Arab Emirates',
    id: 5,
    dialCode: '+971',
    wisenetCode: '4216',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'AE',
    flag: '🇦🇪'
  },
  {
    name: 'United Kingdom',
    id: 63,
    dialCode: '+44',
    wisenetCode: '2100',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'GB',
    flag: '🇬🇧'
  },
  {
    name: 'United States',
    id: 184,
    dialCode: '+1',
    wisenetCode: '8104',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'US',
    flag: '🇺🇸'
  },
  {
    name: 'Afghanistan',
    id: 1,
    dialCode: '+93',
    wisenetCode: '7201',
    rapidId: 'NIL',
    paypalAllowed: false,
    countryCode: 'AF',
    flag: '🇦🇫'
  },
  {
    name: 'Albania',
    id: 3,
    dialCode: '+355',
    wisenetCode: '3201',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'AL',
    flag: '🇦🇱'
  },
  {
    name: 'Algeria',
    id: 50,
    dialCode: '+213',
    wisenetCode: '4101',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'DZ',
    flag: '🇩🇿'
  },
  {
    name: 'Andorra',
    id: 4,
    dialCode: '+376',
    wisenetCode: '3101',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'AD',
    flag: '🇦🇩'
  },
  {
    name: 'Angola',
    id: 2,
    dialCode: '+244',
    wisenetCode: '9201',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'AO',
    flag: '🇦🇴'
  },
  {
    name: 'Antigua and Barbuda',
    id: 8,
    dialCode: '+1268',
    wisenetCode: '8402',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'AG',
    flag: '🇦🇬'
  },
  {
    name: 'Argentina',
    id: 6,
    dialCode: '+54',
    wisenetCode: '8201',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'AR',
    flag: '🇦🇷'
  },
  {
    name: 'Armenia',
    id: 7,
    dialCode: '+374',
    wisenetCode: '7202',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'AM',
    flag: '🇦🇲'
  },

  {
    name: 'Austria',
    id: 10,
    dialCode: '+43',
    wisenetCode: '2301',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'AT',
    flag: '🇦🇹'
  },
  {
    name: 'Azerbaijan',
    id: 11,
    dialCode: '+994',
    wisenetCode: '7203',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'AZ',
    flag: '🇦🇿'
  },
  {
    name: 'Bahamas',
    id: 19,
    dialCode: '+1242',
    wisenetCode: '8404',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'BS',
    flag: '🇧🇸'
  },
  {
    name: 'Bahrain',
    id: 18,
    dialCode: '+973',
    wisenetCode: '4201',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'BH',
    flag: '🇧🇭'
  },
  {
    name: 'Bangladesh',
    id: 16,
    dialCode: '+880',
    wisenetCode: '7101',
    rapidId: 'NIL',
    paypalAllowed: false,
    countryCode: 'BD',
    flag: '🇧🇩'
  },
  {
    name: 'Barbados',
    id: 25,
    dialCode: '+1246',
    wisenetCode: '8405',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'BB',
    flag: '🇧🇧'
  },
  {
    name: 'Belarus',
    id: 21,
    dialCode: '+375',
    wisenetCode: '3301',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'BY',
    flag: '🇧🇾'
  },
  {
    name: 'Belgium',
    id: 13,
    dialCode: '+32',
    wisenetCode: '2302',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'BE',
    flag: '🇧🇪'
  },
  {
    name: 'Belize',
    id: 22,
    dialCode: '+501',
    wisenetCode: '8301',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'BZ',
    flag: '🇧🇿'
  },
  {
    name: 'Benin',
    id: 14,
    dialCode: '+229',
    wisenetCode: '9101',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'BJ',
    flag: '🇧🇯'
  },
  {
    name: 'Bhutan',
    id: 27,
    dialCode: '+975',
    wisenetCode: '7102',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'BT',
    flag: '🇧🇹'
  },
  {
    name: 'Bolivia',
    id: 23,
    dialCode: '+591',
    wisenetCode: '8202',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'BO',
    flag: '🇧🇴'
  },
  {
    name: 'Bosnia and Herzegovina',
    id: 20,
    dialCode: '+387',
    wisenetCode: '3202',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'BA',
    flag: '🇧🇦'
  },
  {
    name: 'Botswana',
    id: 28,
    dialCode: '+267',
    wisenetCode: '9202',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'BW',
    flag: '🇧🇼'
  },
  {
    name: 'Brazil',
    id: 24,
    dialCode: '+55',
    wisenetCode: '8203',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'BR',
    flag: '🇧🇷'
  },
  {
    name: 'Brunei Darussalam',
    id: 26,
    dialCode: '+673',
    wisenetCode: '5201',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'BN',
    flag: '🇧🇳'
  },
  {
    name: 'Bulgaria',
    id: 17,
    dialCode: '+359',
    wisenetCode: '3203',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'BG',
    flag: '🇧🇬'
  },
  {
    name: 'Burkina Faso',
    id: 15,
    dialCode: '+226',
    wisenetCode: '9102',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'BF',
    flag: '🇧🇫'
  },
  {
    name: 'Burundi',
    id: 12,
    dialCode: '+257',
    wisenetCode: '9203',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'BI',
    flag: '🇧🇮'
  },
  {
    name: 'Cambodia',
    id: 92,
    dialCode: '+855',
    wisenetCode: '5102',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'KH',
    flag: '🇰🇭'
  },
  {
    name: 'Cameroon',
    id: 35,
    dialCode: '+237',
    wisenetCode: '9103',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'CM',
    flag: '🇨🇲'
  },
  {
    name: 'Canada',
    id: 30,
    dialCode: '+1',
    wisenetCode: '8102',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'CA',
    flag: '🇨🇦'
  },
  {
    name: 'Cape Verde',
    id: 40,
    dialCode: '+238',
    wisenetCode: '9104',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'CV',
    flag: '🇨🇻'
  },
  {
    name: 'Central African Republic',
    id: 29,
    dialCode: '+236',
    wisenetCode: '9105',
    rapidId: 'NIL',
    paypalAllowed: false,
    countryCode: 'CF',
    flag: '🇨🇫'
  },
  {
    name: 'Chad',
    id: 168,
    dialCode: '+235',
    wisenetCode: '9106',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'TD',
    flag: '🇹🇩'
  },
  {
    name: 'Chile',
    id: 32,
    dialCode: '+56',
    wisenetCode: '8204',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'CL',
    flag: '🇨🇱'
  },
  {
    name: 'China',
    id: 33,
    dialCode: '+86',
    wisenetCode: '6101',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'CN',
    flag: '🇨🇳'
  },
  {
    name: 'Colombia',
    id: 38,
    dialCode: '+57',
    wisenetCode: '8205',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'CO',
    flag: '🇨🇴'
  },
  {
    name: 'Comoros',
    id: 39,
    dialCode: '+269',
    wisenetCode: '9204',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'KM',
    flag: '🇰🇲'
  },
  {
    name: 'Cook Islands',
    id: 10006,
    dialCode: '+682',
    wisenetCode: '',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'CK',
    flag: '🇨🇰'
  },
  {
    name: 'Curaçao',
    id: 10004,
    dialCode: '+599',
    wisenetCode: '',
    rapidId: 'NIL',
    paypalAllowed: false,
    countryCode: 'CW',
    flag: '🇨🇼'
  },
  {
    name: "People's Republic of the Congo",
    id: 37,
    dialCode: '+242',
    wisenetCode: '9107',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'CG',
    flag: '🇨🇬'
  },
  {
    name: 'Democratic Republic of the Congo',
    id: 36,
    dialCode: '+243',
    wisenetCode: '9108',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'CD',
    flag: '🇨🇩'
  },
  {
    name: 'Costa Rica',
    id: 41,
    dialCode: '+506',
    wisenetCode: '8302',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'CR',
    flag: '🇨🇷'
  },
  {
    name: 'Croatia (Hrvatska)',
    id: 75,
    dialCode: '+385',
    wisenetCode: '3204',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'HR',
    flag: '🇭🇷'
  },
  {
    name: 'Cuba',
    id: 42,
    dialCode: '+53',
    wisenetCode: '8407',
    rapidId: 'NIL',
    paypalAllowed: false,
    countryCode: 'CU',
    flag: '🇨🇺'
  },
  {
    name: 'Cyprus',
    id: 43,
    dialCode: '+357',
    wisenetCode: '3205',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'CY',
    flag: '🇨🇾'
  },
  {
    name: 'Czech Republic',
    id: 44,
    dialCode: '+420',
    wisenetCode: '3302',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'CZ',
    flag: '🇨🇿'
  },
  {
    name: 'Denmark',
    id: 48,
    dialCode: '+45',
    wisenetCode: '2401',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'DK',
    flag: '🇩🇰'
  },
  {
    name: 'Djibouti',
    id: 46,
    dialCode: '+253',
    wisenetCode: '9205',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'DJ',
    flag: '🇩🇯'
  },
  {
    name: 'Dominica',
    id: 47,
    dialCode: '+1767',
    wisenetCode: '8408',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'DM',
    flag: '🇩🇲'
  },
  {
    name: 'Dominican Republic',
    id: 49,
    dialCode: '+1',
    wisenetCode: '8411',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'DO',
    flag: '🇩🇴'
  },
  {
    name: 'Timor-Leste',
    id: 173,
    dialCode: '+670',
    wisenetCode: '5206',
    rapidId: 'NIL',
    paypalAllowed: false,
    countryCode: 'TL',
    flag: '🇹🇱'
  },
  {
    name: 'Ecuador',
    id: 51,
    dialCode: '+593',
    wisenetCode: '8206',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'EC',
    flag: '🇪🇨'
  },
  {
    name: 'Egypt',
    id: 52,
    dialCode: '+20',
    wisenetCode: '4102',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'EG',
    flag: '🇪🇬'
  },
  {
    name: 'El Salvador',
    id: 156,
    dialCode: '+503',
    wisenetCode: '8303',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'SV',
    flag: '🇸🇻'
  },
  {
    name: 'Equatorial Guinea',
    id: 69,
    dialCode: '+240',
    wisenetCode: '9112',
    rapidId: 'NIL',
    paypalAllowed: false,
    countryCode: 'GQ',
    flag: '🇬🇶'
  },
  {
    name: 'Eritrea',
    id: 53,
    dialCode: '+291',
    wisenetCode: '9206',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'ER',
    flag: '🇪🇷'
  },
  {
    name: 'Estonia',
    id: 56,
    dialCode: '+372',
    wisenetCode: '3303',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'EE',
    flag: '🇪🇪'
  },
  {
    name: 'Ethiopia',
    id: 57,
    dialCode: '+251',
    wisenetCode: '9207',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'ET',
    flag: '🇪🇹'
  },
  {
    name: 'Fiji',
    id: 59,
    dialCode: '+679',
    wisenetCode: '1502',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'FJ',
    flag: '🇫🇯'
  },
  {
    name: 'Finland',
    id: 58,
    dialCode: '+358',
    wisenetCode: '2403',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'FI',
    flag: '🇫🇮'
  },
  {
    name: 'France',
    id: 60,
    dialCode: '+33',
    wisenetCode: '2303',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'FR',
    flag: '🇫🇷'
  },
  {
    name: 'French Polynesia',
    id: 2000,
    dialCode: '+689',
    wisenetCode: '5205',
    rapidId: 'PF',
    paypalAllowed: true,
    countryCode: 'PF',
    flag: '🇵🇫'
  },
  {
    name: 'Gabon',
    id: 62,
    dialCode: '+241',
    wisenetCode: '9113',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'GA',
    flag: '🇬🇦'
  },
  {
    name: 'Gambia',
    id: 67,
    dialCode: '+220',
    wisenetCode: '9114',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'GM',
    flag: '🇬🇲'
  },
  {
    name: 'Georgia',
    id: 64,
    dialCode: '+995',
    wisenetCode: '7204',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'GE',
    flag: '🇬🇪'
  },
  {
    name: 'Germany',
    id: 45,
    dialCode: '+49',
    wisenetCode: '2304',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'DE',
    flag: '🇩🇪'
  },
  {
    name: 'Ghana',
    id: 65,
    dialCode: '+233',
    wisenetCode: '9115',
    rapidId: 'NIL',
    paypalAllowed: false,
    countryCode: 'GH',
    flag: '🇬🇭'
  },
  {
    name: 'Greece',
    id: 70,
    dialCode: '+30',
    wisenetCode: '3207',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'GR',
    flag: '🇬🇷'
  },
  {
    name: 'Greenland',
    id: 10005,
    dialCode: '+299',
    wisenetCode: '2404',
    rapidId: 'NIL',
    paypalAllowed: false,
    countryCode: 'GL',
    flag: '🇬🇱'
  },
  {
    name: 'Grenada',
    id: 71,
    dialCode: '+1473',
    wisenetCode: '8412',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'GD',
    flag: '🇬🇩'
  },
  {
    name: 'Guatemala',
    id: 72,
    dialCode: '+502',
    wisenetCode: '8304',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'GT',
    flag: '🇬🇹'
  },
  {
    name: 'Guinea',
    id: 66,
    dialCode: '+224',
    wisenetCode: '9116',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'GN',
    flag: '🇬🇳'
  },
  {
    name: 'Guinea-Bissau',
    id: 68,
    dialCode: '+245',
    wisenetCode: '9117',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'GW',
    flag: '🇬🇼'
  },
  {
    name: 'Guyana',
    id: 73,
    dialCode: '+592',
    wisenetCode: '8211',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'GY',
    flag: '🇬🇾'
  },
  {
    name: 'Haiti',
    id: 76,
    dialCode: '+509',
    wisenetCode: '8414',
    rapidId: 'NIL',
    paypalAllowed: false,
    countryCode: 'HT',
    flag: '🇭🇹'
  },
  {
    name: 'Honduras',
    id: 74,
    dialCode: '+504',
    wisenetCode: '8305',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'HN',
    flag: '🇭🇳'
  },
  {
    name: 'Hong Kong',
    id: 0,
    dialCode: '+852',
    wisenetCode: '',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'HK',
    flag: '🇭🇰'
  },
  {
    name: 'Hungary',
    id: 77,
    dialCode: '+36',
    wisenetCode: '3304',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'HU',
    flag: '🇭🇺'
  },
  {
    name: 'Iceland',
    id: 83,
    dialCode: '+354',
    wisenetCode: '2405',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'IS',
    flag: '🇮🇸'
  },
  {
    name: 'Iran',
    id: 81,
    dialCode: '+98',
    wisenetCode: '4203',
    rapidId: 'NIL',
    paypalAllowed: false,
    countryCode: 'IR',
    flag: '🇮🇷'
  },
  {
    name: 'Iraq',
    id: 82,
    dialCode: '+964',
    wisenetCode: '4204',
    rapidId: 'NIL',
    paypalAllowed: false,
    countryCode: 'IQ',
    flag: '🇮🇶'
  },
  {
    name: 'Ireland',
    id: 80,
    dialCode: '+353',
    wisenetCode: '2201',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'IE',
    flag: '🇮🇪'
  },
  {
    name: 'Israel',
    id: 84,
    dialCode: '+972',
    wisenetCode: '4205',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'IL',
    flag: '🇮🇱'
  },
  {
    name: 'Italy',
    id: 85,
    dialCode: '+39',
    wisenetCode: '3104',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'IT',
    flag: '🇮🇹'
  },
  {
    name: 'Ivory Coast',
    id: 34,
    dialCode: '+225',
    wisenetCode: '9111',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'CI',
    flag: '🇨🇮'
  },
  {
    name: 'Jamaica',
    id: 86,
    dialCode: '+1876',
    wisenetCode: '8415',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'JM',
    flag: '🇯🇲'
  },
  {
    name: 'Japan',
    id: 88,
    dialCode: '+81',
    wisenetCode: '6201',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'JP',
    flag: '🇯🇵'
  },
  {
    name: 'Jersey',
    id: 10003,
    dialCode: '+44',
    wisenetCode: '',
    rapidId: 'NIL',
    paypalAllowed: false,
    countryCode: 'JE',
    flag: '🇯🇪'
  },
  {
    name: 'Jordan',
    id: 87,
    dialCode: '+962',
    wisenetCode: '4206',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'JO',
    flag: '🇯🇴'
  },
  {
    name: 'Kazakhstan',
    id: 89,
    dialCode: '+77',
    wisenetCode: '7205',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'KZ',
    flag: '🇰🇿'
  },
  {
    name: 'Kenya',
    id: 90,
    dialCode: '+254',
    wisenetCode: '9208',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'KE',
    flag: '🇰🇪'
  },
  {
    name: 'Kiribati',
    id: 93,
    dialCode: '+686',
    wisenetCode: '1402',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'KI',
    flag: '🇰🇮'
  },
  {
    name: 'North Korea',
    id: 10001,
    dialCode: '+850',
    wisenetCode: '',
    rapidId: 'NIL',
    paypalAllowed: false,
    countryCode: 'KP',
    flag: '🇰🇵'
  },
  {
    name: 'South Korea',
    id: 95,
    dialCode: '+82',
    wisenetCode: '6203',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'KR',
    flag: '🇰🇷'
  },
  {
    name: 'Kosovo',
    id: 10002,
    dialCode: '+383',
    wisenetCode: '',
    rapidId: 'NIL',
    paypalAllowed: false,
    countryCode: 'XK',
    flag: '🇽🇰'
  },
  {
    name: 'Kuwait',
    id: 96,
    dialCode: '+965',
    wisenetCode: '4207',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'KW',
    flag: '🇰🇼'
  },
  {
    name: 'Kyrgyzstan',
    id: 91,
    dialCode: '+996',
    wisenetCode: '7206',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'KG',
    flag: '🇰🇬'
  },
  {
    name: 'Laos',
    id: 97,
    dialCode: '+856',
    wisenetCode: '5103',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'LA',
    flag: '🇱🇦'
  },
  {
    name: 'Latvia',
    id: 107,
    dialCode: '+371',
    wisenetCode: '3305',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'LV',
    flag: '🇱🇻'
  },
  {
    name: 'Lebanon',
    id: 98,
    dialCode: '+961',
    wisenetCode: '4208',
    rapidId: 'NIL',
    paypalAllowed: false,
    countryCode: 'LB',
    flag: '🇱🇧'
  },
  {
    name: 'Lesotho',
    id: 104,
    dialCode: '+266',
    wisenetCode: '9211',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'LS',
    flag: '🇱🇸'
  },
  {
    name: 'Liberia',
    id: 99,
    dialCode: '+231',
    wisenetCode: '9118',
    rapidId: 'NIL',
    paypalAllowed: false,
    countryCode: 'LR',
    flag: '🇱🇷'
  },
  {
    name: 'Libya',
    id: 100,
    dialCode: '+218',
    wisenetCode: '4103',
    rapidId: 'NIL',
    paypalAllowed: false,
    countryCode: 'LY',
    flag: '🇱🇾'
  },
  {
    name: 'Liechtenstein',
    id: 102,
    dialCode: '+423',
    wisenetCode: '2305',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'LI',
    flag: '🇱🇮'
  },
  {
    name: 'Lithuania',
    id: 105,
    dialCode: '+370',
    wisenetCode: '3306',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'LT',
    flag: '🇱🇹'
  },
  {
    name: 'Luxembourg',
    id: 106,
    dialCode: '+352',
    wisenetCode: '2306',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'LU',
    flag: '🇱🇺'
  },
  {
    name: 'North Macedonia',
    id: 115,
    dialCode: '+389',
    wisenetCode: '3206',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'MK',
    flag: '🇲🇰'
  },
  {
    name: 'Madagascar',
    id: 111,
    dialCode: '+261',
    wisenetCode: '9212',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'MG',
    flag: '🇲🇬'
  },
  {
    name: 'Malawi',
    id: 124,
    dialCode: '+265',
    wisenetCode: '9213',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'MW',
    flag: '🇲🇼'
  },
  {
    name: 'Maldives',
    id: 112,
    dialCode: '+960',
    wisenetCode: '7104',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'MV',
    flag: '🇲🇻'
  },
  {
    name: 'Mali',
    id: 116,
    dialCode: '+223',
    wisenetCode: '9121',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'ML',
    flag: '🇲🇱'
  },
  {
    name: 'Malta',
    id: 117,
    dialCode: '+356',
    wisenetCode: '3105',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'MT',
    flag: '🇲🇹'
  },
  {
    name: 'Marshall Islands',
    id: 114,
    dialCode: '+692',
    wisenetCode: '1403',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'MH',
    flag: '🇲🇭'
  },
  {
    name: 'Mauritania',
    id: 122,
    dialCode: '+222',
    wisenetCode: '9122',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'MR',
    flag: '🇲🇷'
  },
  {
    name: 'Mauritius',
    id: 123,
    dialCode: '+230',
    wisenetCode: '9214',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'MU',
    flag: '🇲🇺'
  },
  {
    name: 'Mexico',
    id: 113,
    dialCode: '+52',
    wisenetCode: '8306',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'MX',
    flag: '🇲🇽'
  },
  {
    name: 'Micronesia',
    id: 61,
    dialCode: '+691',
    wisenetCode: '1404',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'FM',
    flag: '🇫🇲'
  },
  {
    name: 'Moldova',
    id: 110,
    dialCode: '+373',
    wisenetCode: '3208',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'MD',
    flag: '🇲🇩'
  },
  {
    name: 'Monaco',
    id: 109,
    dialCode: '+377',
    wisenetCode: '2307',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'MC',
    flag: '🇲🇨'
  },
  {
    name: 'Mongolia',
    id: 120,
    dialCode: '+976',
    wisenetCode: '6104',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'MN',
    flag: '🇲🇳'
  },
  {
    name: 'Montenegro',
    id: 119,
    dialCode: '+382',
    wisenetCode: '3214',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'ME',
    flag: '🇲🇪'
  },
  {
    name: 'Morocco',
    id: 108,
    dialCode: '+212',
    wisenetCode: '4104',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'MA',
    flag: '🇲🇦'
  },
  {
    name: 'Mozambique',
    id: 121,
    dialCode: '+258',
    wisenetCode: '9216',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'MZ',
    flag: '🇲🇿'
  },
  {
    name: 'Myanmar (Burma)',
    id: 118,
    dialCode: '+95',
    wisenetCode: '5101',
    rapidId: 'NIL',
    paypalAllowed: false,
    countryCode: 'MM',
    flag: '🇲🇲'
  },
  {
    name: 'Namibia',
    id: 126,
    dialCode: '+264',
    wisenetCode: '9217',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'NA',
    flag: '🇳🇦'
  },
  {
    name: 'Nauru',
    id: 133,
    dialCode: '+674',
    wisenetCode: '1405',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'NR',
    flag: '🇳🇷'
  },
  {
    name: 'Nepal',
    id: 132,
    dialCode: '+977',
    wisenetCode: '7105',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'NP',
    flag: '🇳🇵'
  },
  {
    name: 'Netherlands',
    id: 130,
    dialCode: '+31',
    wisenetCode: '2308',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'NL',
    flag: '🇳🇱'
  },
  {
    name: 'New Zealand',
    id: 134,
    dialCode: '+64',
    wisenetCode: '1201',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'NZ',
    flag: '🇳🇿'
  },
  {
    name: 'Nicaragua',
    id: 129,
    dialCode: '+505',
    wisenetCode: '8307',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'NI',
    flag: '🇳🇮'
  },
  {
    name: 'Niger',
    id: 127,
    dialCode: '+227',
    wisenetCode: '9123',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'NE',
    flag: '🇳🇪'
  },
  {
    name: 'Nigeria',
    id: 128,
    dialCode: '+234',
    wisenetCode: '9124',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'NG',
    flag: '🇳🇬'
  },
  {
    name: 'Norway',
    id: 131,
    dialCode: '+47',
    wisenetCode: '2406',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'NO',
    flag: '🇳🇴'
  },
  {
    name: 'Oman',
    id: 135,
    dialCode: '+968',
    wisenetCode: '4211',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'OM',
    flag: '🇴🇲'
  },
  {
    name: 'Pakistan',
    id: 136,
    dialCode: '+92',
    wisenetCode: '7106',
    rapidId: 'NIL',
    paypalAllowed: false,
    countryCode: 'PK',
    flag: '🇵🇰'
  },
  {
    name: 'Palau',
    id: 140,
    dialCode: '+680',
    wisenetCode: '1407',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'PW',
    flag: '🇵🇼'
  },
  {
    name: 'Palestine',
    id: 10003,
    dialCode: '+970',
    wisenetCode: '',
    rapidId: 'NIL',
    paypalAllowed: false,
    countryCode: 'PS',
    flag: '🇵🇸'
  },
  {
    name: 'Panama',
    id: 137,
    dialCode: '+507',
    wisenetCode: '8308',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'PA',
    flag: '🇵🇦'
  },
  {
    name: 'Papua New Guinea',
    id: 141,
    dialCode: '+675',
    wisenetCode: '1302',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'PG',
    flag: '🇵🇬'
  },
  {
    name: 'Paraguay',
    id: 144,
    dialCode: '+595',
    wisenetCode: '8212',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'PY',
    flag: '🇵🇾'
  },
  {
    name: 'Peru',
    id: 138,
    dialCode: '+51',
    wisenetCode: '8213',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'PE',
    flag: '🇵🇪'
  },
  {
    name: 'Poland',
    id: 142,
    dialCode: '+48',
    wisenetCode: '3307',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'PL',
    flag: '🇵🇱'
  },
  {
    name: 'Portugal',
    id: 143,
    dialCode: '+351',
    wisenetCode: '3106',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'PT',
    flag: '🇵🇹'
  },
  // add puerto rico
  {
    name: 'Puerto Rico',
    id: 630,
    dialCode: '+1',
    wisenetCode: '8416',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'PR',
    flag: '🇵🇷'
  },
  {
    name: 'Qatar',
    id: 146,
    dialCode: '+974',
    wisenetCode: '4212',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'QA',
    flag: '🇶🇦'
  },
  {
    name: 'Romania',
    id: 147,
    dialCode: '+40',
    wisenetCode: '3211',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'RO',
    flag: '🇷🇴'
  },
  {
    name: 'Russia',
    id: 148,
    dialCode: '+7',
    wisenetCode: '3308',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'RU',
    flag: '🇷🇺'
  },
  {
    name: 'Rwanda',
    id: 149,
    dialCode: '+250',
    wisenetCode: '9221',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'RW',
    flag: '🇷🇼'
  },
  {
    name: 'Saint Kitts and Nevis',
    id: 94,
    dialCode: '+1869',
    wisenetCode: '8422',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'KN',
    flag: '🇰🇳'
  },
  {
    name: 'Saint Lucia',
    id: 101,
    dialCode: '+1758',
    wisenetCode: '8423',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'LC',
    flag: '🇱🇨'
  },
  {
    name: 'Saint Vincent and the Grenadines',
    id: 187,
    dialCode: '+1784',
    wisenetCode: '8424',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'VC',
    flag: '🇻🇨'
  },
  {
    name: 'Samoa',
    id: 191,
    dialCode: '+685',
    wisenetCode: '1505',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'WS',
    flag: '🇼🇸'
  },
  {
    name: 'San Marino',
    id: 157,
    dialCode: '+378',
    wisenetCode: '3107',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'SM',
    flag: '🇸🇲'
  },
  {
    name: 'Sao Tomé and Principe',
    id: 160,
    dialCode: '+239',
    wisenetCode: '9125',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'ST',
    flag: '🇸🇹'
  },
  {
    name: 'Saudi Arabia',
    id: 150,
    dialCode: '+966',
    wisenetCode: '4213',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'SA',
    flag: '🇸🇦'
  },
  {
    name: 'Senegal',
    id: 152,
    dialCode: '+221',
    wisenetCode: '9126',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'SN',
    flag: '🇸🇳'
  },
  {
    name: 'Serbia',
    id: 159,
    dialCode: '+381',
    wisenetCode: '3215',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'RS',
    flag: '🇷🇸'
  },
  {
    name: 'Seychelles',
    id: 166,
    dialCode: '+248',
    wisenetCode: '9223',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'SC',
    flag: '🇸🇨'
  },
  {
    name: 'Sierra Leone',
    id: 155,
    dialCode: '+232',
    wisenetCode: '9127',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'SL',
    flag: '🇸🇱'
  },
  {
    name: 'Slovakia',
    id: 162,
    dialCode: '+421',
    wisenetCode: '3311',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'SK',
    flag: '🇸🇰'
  },
  {
    name: 'Slovenia',
    id: 163,
    dialCode: '+386',
    wisenetCode: '3212',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'SI',
    flag: '🇸🇮'
  },
  {
    name: 'Solomon Islands',
    id: 154,
    dialCode: '+677',
    wisenetCode: '1303',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'SB',
    flag: '🇸🇧'
  },
  {
    name: 'Somalia',
    id: 158,
    dialCode: '+252',
    wisenetCode: '9224',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'SO',
    flag: '🇸🇴'
  },
  {
    name: 'South Africa',
    id: 193,
    dialCode: '+27',
    wisenetCode: '9225',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'ZA',
    flag: '🇿🇦'
  },
  {
    name: 'South Sudan',
    id: 197,
    dialCode: '+211',
    wisenetCode: '4111',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'SS',
    flag: '🇸🇸'
  },
  {
    name: 'Spain',
    id: 55,
    dialCode: '+34',
    wisenetCode: '3108',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'ES',
    flag: '🇪🇸'
  },
  {
    name: 'Sri Lanka',
    id: 103,
    dialCode: '+94',
    wisenetCode: '7107',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'LK',
    flag: '🇱🇰'
  },
  {
    name: 'Sudan',
    id: 151,
    dialCode: '+249',
    wisenetCode: '4105',
    rapidId: 'NIL',
    paypalAllowed: false,
    countryCode: 'SD',
    flag: '🇸🇩'
  },
  {
    name: 'Suriname',
    id: 161,
    dialCode: '+597',
    wisenetCode: '8214',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'SR',
    flag: '🇸🇷'
  },
  {
    name: 'Swaziland',
    id: 165,
    dialCode: '+268',
    wisenetCode: '9226',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'SZ',
    flag: '🇸🇿'
  },
  {
    name: 'Sweden',
    id: 164,
    dialCode: '+46',
    wisenetCode: '2407',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'SE',
    flag: '🇸🇪'
  },
  {
    name: 'Switzerland',
    id: 31,
    dialCode: '+41',
    wisenetCode: '2311',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'CH',
    flag: '🇨🇭'
  },
  {
    name: 'Syrian Arab Republic',
    id: 167,
    dialCode: '+963',
    wisenetCode: '4214',
    rapidId: 'NIL',
    paypalAllowed: false,
    countryCode: 'SY',
    flag: '🇸🇾'
  },
  {
    name: 'Taiwan',
    id: 179,
    dialCode: '+886',
    wisenetCode: '6105',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'TW',
    flag: '🇹🇼'
  },
  {
    name: 'Tajikistan',
    id: 171,
    dialCode: '+992',
    wisenetCode: '7207',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'TJ',
    flag: '🇹🇯'
  },
  {
    name: 'Tanzania',
    id: 180,
    dialCode: '+255',
    wisenetCode: '9227',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'TZ',
    flag: '🇹🇿'
  },
  {
    name: 'Thailand',
    id: 170,
    dialCode: '+66',
    wisenetCode: '5104',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'TH',
    flag: '🇹🇭'
  },
  {
    name: 'Togo',
    id: 169,
    dialCode: '+228',
    wisenetCode: '9128',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'TG',
    flag: '🇹🇬'
  },
  {
    name: 'Tonga',
    id: 174,
    dialCode: '+676',
    wisenetCode: '1508',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'TO',
    flag: '🇹🇴'
  },
  {
    name: 'Trinidad and Tobago',
    id: 175,
    dialCode: '+1868',
    wisenetCode: '8425',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'TT',
    flag: '🇹🇹'
  },
  {
    name: 'Tunisia',
    id: 176,
    dialCode: '+216',
    wisenetCode: '4106',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'TN',
    flag: '🇹🇳'
  },
  {
    name: 'Turkey',
    id: 177,
    dialCode: '+90',
    wisenetCode: '4215',
    rapidId: 'NIL',
    paypalAllowed: false,
    countryCode: 'TR',
    flag: '🇹🇷'
  },
  {
    name: 'Turks and Caicos Islands',
    id: 2001,
    dialCode: '+1',
    wisenetCode: '5205',
    rapidId: 'TC',
    paypalAllowed: true,
    countryCode: 'TC',
    flag: '🇹🇨'
  },
  {
    name: 'Turkmenistan',
    id: 172,
    dialCode: '+993',
    wisenetCode: '7208',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'TM',
    flag: '🇹🇲'
  },
  {
    name: 'Tuvalu',
    id: 178,
    dialCode: '+688',
    wisenetCode: '1511',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'TV',
    flag: '🇹🇻'
  },
  {
    name: 'Uganda',
    id: 181,
    dialCode: '+256',
    wisenetCode: '9228',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'UG',
    flag: '🇺🇬'
  },
  {
    name: 'Ukraine',
    id: 182,
    dialCode: '+380',
    wisenetCode: '3312',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'UA',
    flag: '🇺🇦'
  },

  {
    name: 'Uruguay',
    id: 183,
    dialCode: '+598',
    wisenetCode: '8215',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'UY',
    flag: '🇺🇾'
  },
  {
    name: 'Uzbekistan',
    id: 185,
    dialCode: '+998',
    wisenetCode: '7211',
    rapidId: 'NIL',
    paypalAllowed: false,
    countryCode: 'UZ',
    flag: '🇺🇿'
  },
  {
    name: 'Vanuatu',
    id: 190,
    dialCode: '+678',
    wisenetCode: '1304',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'VU',
    flag: '🇻🇺'
  },
  {
    name: 'Vatican City',
    id: 186,
    dialCode: '+379',
    wisenetCode: '3103',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'VA',
    flag: '🇻🇦'
  },
  {
    name: 'Venezuela',
    id: 188,
    dialCode: '+58',
    wisenetCode: '8216',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'VE',
    flag: '🇻🇪'
  },
  {
    name: 'Vietnam',
    id: 189,
    dialCode: '+84',
    wisenetCode: '5105',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'VN',
    flag: '🇻🇳'
  },
  {
    name: 'Yemen',
    id: 192,
    dialCode: '+967',
    wisenetCode: '4217',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'YE',
    flag: '🇾🇪'
  },
  {
    name: 'Zambia',
    id: 194,
    dialCode: '+260',
    wisenetCode: '9231',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'ZM',
    flag: '🇿🇲'
  },
  {
    name: 'Zimbabwe',
    id: 195,
    dialCode: '+263',
    wisenetCode: '9232',
    rapidId: 'NIL',
    paypalAllowed: true,
    countryCode: 'ZW',
    flag: '🇿🇼'
  },
  {
    name: 'Cayman Islands',
    id: 1234,
    dialCode: '+1',
    wisenetCode: '',
    rapidId: 'NIL',
    paypalAllowed: false,
    countryCode: 'KY',
    flag: '🇰🇾'
  },
  {
    name: 'Gibraltar UK',
    id: 999,
    dialCode: '+350',
    wisenetCode: '',
    rapidId: 'GUK',
    paypalAllowed: false,
    countryCode: 'GUK',
    flag: '🇬🇮'
  },
  {
    name: 'Aruba',
    id: 20004,
    dialCode: '+297',
    wisenetCode: '',
    rapidId: 'ABW',
    paypalAllowed: false,
    countryCode: 'AB',
    flag: '🇦🇼'
  },
  {
    name: 'Guadeloupe',
    id: 2002,
    dialCode: '+590',
    wisenetCode: '5210',
    rapidId: 'GP',
    paypalAllowed: false,
    countryCode: 'GP',
    flag: '🇬🇵'
  },
  {
    name: 'Martinique',
    id: 2003,
    dialCode: '+596',
    wisenetCode: '5215',
    rapidId: 'MQ',
    paypalAllowed: false,
    countryCode: 'MQ',
    flag: '🇲🇶'
  },
  {
    name: 'Saint Barthélemy',
    id: 2004,
    dialCode: '+590',
    wisenetCode: '5220',
    rapidId: 'BL',
    paypalAllowed: false,
    countryCode: 'BL',
    flag: '🇧🇱'
  },
  {
    name: 'Saint Martin',
    id: 2005,
    dialCode: '+590',
    wisenetCode: '5225',
    rapidId: 'MF',
    paypalAllowed: false,
    countryCode: 'MF',
    flag: '🇲🇫'
  }
];
